import React from 'react';

import styled from 'styled-components'
import { useTable } from 'react-table'

const Styles = styled.div`
  padding: 1rem;
  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #FFFFFF;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      color: #FFFFFF;
      padding: 0.5rem;
    },
    td {
      margin: 0;
      padding: 0.5rem;
      border-top: 1px solid #FFFFFF;
      border-bottom: 1px solid #FFFFFF;
      border-right: 1px solid #FFFFFF;
      /*
      :last-child {
        border-right: 0;
      }
      */
    }
  }
`

function Table({ columns, data }) {
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
    })

    // Render the UI for your table
    return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    )
  }

export class Servers extends React.Component {

  constructor(props){
    super(props);
    
    this.state = { servers: [], token: props.token };
  }
  
  componentDidMount() {
    // Get dataa
    
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': this.state.token }
    };
    
    
    fetch(document.homeAddress + "servers", requestOptions)
        .then(response => response.json())
        .then(data => {
        
          this.setState({ servers: data.servers });
        })
  }

  render() {
   
    const columns = [
      {
        Header: 'Servers',
        columns: [
          {
            Header: 'Server UUID',
            accessor: 'server_name',
          },
          {
            Header: 'Server Role',
            accessor: 'server_role',
          },
          {
            Header: 'IP Address',
            accessor: 'ip4_address',
          },
          {
            Header: 'Check In',
            accessor: 'check_in',
          },
        ],
      }];
   
    var data = this.state.servers;
   
    return ( 
      <Styles>
        <Table columns={columns} data={data} />
      </Styles>
      );
  }
}