import React from 'react';

export class Projects extends React.Component {

  constructor(props){
    super(props);
    
    
  }
  
  componentDidMount() {
    // Get data
    
    
  }

  render() {
   
    return ( 
      <div>
        Hello
      </div>
      );
  }
}