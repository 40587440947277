import React from 'react';
import './App.css';
import { Button, Nav, Icon, Field, Control } from 'reactbulma';

// Logged in pages
import { Organizations } from './containers/organizations/organizations';
import { Projects } from './containers/projects/projects';
import { Servers } from './containers/servers/servers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserNinja, faChartBar, faList, faClock, faFile, faFilm, faTruck, faFolder, faExclamationTriangle, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faUserNinja, faChartBar, faList, faClock, faFile, faFilm, faTruck, faFolder, faExclamationTriangle, faUser );

class App extends React.Component {

  colors = ['#666666', '#999999', '#FFFFFF', '#333333', '#AAAAAA'];
  //colors = ['red', 'blue', 'green', 'purple', 'white'];

  screenshots = ["0.jpg", "9.jpg", "4.jpg", "5.jpg", "8.jpg", "6.jpg", "7.jpg"];
  description = ["Welcome to Mesoverse:  Gigify your business", "Customizable forms for all functions of business, including signatures and photographs", "Run your business operations from a single app", "Use roles and groups to provide different information to different players", "Make employee onboarding simple, painless and transparent", "Coordinate with calendars, chat and alerts", "Run your business live on maps, with geospatial tools for planning and data capture"];
  ssTime = 4000;

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  constructor(props){
    super(props)
    
    // Set home address
    document.homeAddress = "https://manage.relentlessuniverse.co.za/";
    
    var posX = [];
    var posY = [];
    var dirX = [];
    var dirY = [];
    var colors = [];
    
    this.state = {
      map_img: null,
    
      phoneX: posX,
      phoneY: posY,
      directionX: dirX,
      directionY: dirY,
      
      targetX: [],
      targetY: [],
      targetColors: [],
      
      moneyX: [],
      moneyY: [],
      moneyColors: [],
      
      colors: colors,
      width: 0, 
      height: 0,
      
      menu: 'introduction',
      
      menuOpen: false,
      
      show: true,
      withAnimation: true,
      
      features_io_ul_vis: false,
      features_sched_ul_vis: false,
      features_pol_ul_vis: false,
      features_rep_ul_vis: false,
      
      token: "",
      loggedIn: false,
      loggingIn: false,
      
      email: "",
      password: "",

      screenshotNo: 0,
    }
    
    // Bindings
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.animationFrame = this.animationFrame.bind(this);
    this.animationScreenshot = this.animationScreenshot.bind(this);
    this.setMenu = this.setMenu.bind(this);
    
    // Setup animations
    var animationFrame = this.animationFrame;
    var animationScreenshot = this.animationScreenshot;
    
    setTimeout(function() { 
      animationFrame();
      }, 30); 

    setTimeout(function() { 
        animationScreenshot();
      }, this.ssTime); 
  }
  
  setMenu(menu) {
    var prevMenu = this.state.menu;
   
    this.setState({menu: menu});
    
    if (prevMenu != menu && menu == 'introduction') {
      // Start animations
      var animationFrame = this.animationFrame;
      var animationScreenshot = this.animationScreenshot;
    
      setTimeout(function() { 
        animationFrame();
        }, 30); 

      //setTimeout(function() { 
      //  animationScreenshot();
      // }, this.ssTime); 
    }
  }
  
  setShow(show, base) {
    base.setState({show: show});
  }
  
  toggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen
      });
  }
  
  toggleLoginModal() {
    this.setState({
      loggingIn: !this.state.loggingIn
      });
  }
  
  setEmail(email) {
    this.setState({
      email: email
      });
  }
  
  setPassword(password) {
    this.setState({
      password: password
      });
  }
  
  attemptLogin() {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
          'Accept': 'application/json' },
        body: JSON.stringify({ 
          email: this.state.email,
          password: this.state.password
          })
    };
  
    fetch(document.homeAddress + "login", requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data);
        
          if (data.success == true) {
            this.setState({ token: data.token, loggedIn: true, loggingIn: false, menu: 'organizations' });
          }
        })
  }
  
  logOff() {
    this.setState({ token: "", loggedIn: false, loggingIn: false, menu: 'introduction' });
  }
  
  animationScreenshot() {
    var screenshotNo = this.state.screenshotNo;

    if (screenshotNo != (this.screenshots.length - 1)) {
      this.setState({ screenshotNo: screenshotNo + 1});
    }
    else {
      this.setState({screenshotNo: 0});
    }



    //if (this.state.menu == 'introduction') {

      var animationScreenshot = this.animationScreenshot;

      setTimeout(function() { 
        animationScreenshot();
        }, this.ssTime); 
    //}
  }

  animationFrame() {
    var phoneX = this.state.phoneX;
    var phoneY = this.state.phoneY;
    var directionX = this.state.directionX;
    var directionY = this.state.directionY;
    var targetX = this.state.targetX;
    var targetY = this.state.targetY;
    var moneyX = this.state.moneyX;
    var moneyY = this.state.moneyY;
    var moneyColors = this.state.moneyColors;
  
    phoneX.forEach((_, i) => {
      phoneX[i] = phoneX[i] + this.state.directionX[i] * 5 ;
      phoneY[i] = phoneY[i] + this.state.directionY[i] * 5 ;
    
      if (phoneX[i] > (this.state.width - 20)) {
        directionX[i] = -directionX[i];
      }
    
      if (phoneY[i] > (this.state.height - 50) && directionY[i] > 0) {
        directionY[i] = -directionY[i];
      }
    
      if (phoneX[i] < 20) {
        directionX[i] = -directionX[i];
      }
    
      if (phoneY[i] < 20) {
        directionY[i] = -directionY[i];
      }
      
      this.state.targetX.forEach((_, j) => {
        if (Math.abs(targetX[j] - phoneX[i]) < 15 &&
          Math.abs(targetY[j] - phoneY[i]) < 15) {
          targetX[j] = this.getRandomInt(this.state.width);
          targetY[j] = (this.getRandomInt(this.state.height - 100) + 50);
          
          moneyX.push(phoneX[i]);
          moneyY.push(phoneY[i]);
          moneyColors.push(this.state.colors[j]);
        }
      });
    });
    
    moneyX.forEach((_, i) => {
      moneyX[i] = moneyX[i] + 5 ;
    });
    
    var moneyXR = [];
    var moneyYR = [];
    var moneyColorsR = [];
    
    
    moneyX = moneyX.forEach((_, i) => {
      if (moneyX[i] < (this.state.width - 100)) {
        moneyXR.push(_);
        moneyYR.push(moneyY[i]);
        moneyColorsR.push(moneyColors[i]);
      }
    });
    
    this.setState({
      /*
      phoneX: phoneX,
      phoneY: phoneY,
      directionX: directionX,
      directionY: directionY,
      targetX: targetX,
      targetY: targetY,*/
      moneyX: moneyXR,
      moneyY: moneyYR,
      moneyColors: moneyColorsR,
    });
    
    var animationFrame = this.animationFrame;
    
    if (this.state.menu == 'introduction') {
      setTimeout(function() { 
        animationFrame();
        }, 30); 
    }
  }
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('load', this.updateWindowDimensions);

    this.screenshots.forEach((picture) => {
      let img = new Image();
      img.src = picture.fileName;
      console.log(picture);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
  
    // Check whether our map is live
    var map_img = document.getElementById('south africa');
    
    // Process animation
  
    const height = document.getElementById('main').offsetHeight;
  
    var targetX = [];
    var targetY = [];
    var targetColors = [];
    
    var posX = [];
    var posY = [];
    var dirX = [];
    var dirY = [];
    var colors = [];
  
    for (var j = 0; j < 25; j++) {
      targetX.push(this.getRandomInt(window.innerWidth - 50) + 50);
      targetY.push(this.getRandomInt(height - 100) + 50);
      targetColors.push(this.colors[this.getRandomInt(5)]);
    }
    
    for (var j = 0; j < 25; j++) {
      posX.push(this.getRandomInt(window.innerWidth - 50) + 50);
      posY.push(this.getRandomInt(height - 50) + 50);
      dirX.push((this.getRandomInt(2) - 0.5) * 2);
      dirY.push((this.getRandomInt(2) - 0.5) * 2);
      colors.push(this.colors[this.getRandomInt(5)]);
    }
  
    this.setState({ 
      map_img: map_img,
    
      phoneX: posX,
      phoneY: posY,
      directionX: dirX,
      directionY: dirY,
      
      colors: colors,
      
      targetX: targetX,
      targetY: targetY,
      targetColors: targetColors,
      width: window.innerWidth, 
      height: height
      });
  }
  
  render() {
   
    return (
    <section id="main" className="main">
      
      
    
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          { /* <a href="/"><img className="title-logo" src="uni-logo-bw.png" /></a> */}

          <a role="button" className={`navbar-burger burger ${ this.state.menuOpen ? 'is-active' : '' }`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample"
            onKeyDown={() => this.toggleMenu() }
            onClick={() => this.toggleMenu() }>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" className={`navbar-menu ${ this.state.menuOpen ? 'is-active' : '' }`}>
          { !this.state.loggedIn ?
          <div className="navbar-start noselect">
            <img className="logo-image" src="/Logouni.png"/>
            <a onClick={ () => {
              if (this.state.withAnimation) {
                this.setShow(false, this);
                this.setMenu('introduction');
              
                const setShow = this.setShow;
                const base = this;
              
                setTimeout(function() { 
                  setShow(true, base);
                  }, 200);
              }
              else {
                this.setMenu('introduction');
              }
              }
              }
              className={`navbar-item ${this.state.menu == 'introduction' ? "navbar-live" : ""}`}>
              MESOVERSE
            </a>
            
            <a onClick={ () => {
              if (this.state.withAnimation) {
                this.setShow(false, this);
                this.setMenu('applications');
              
                const setShow = this.setShow;
                const base = this;
              
                setTimeout(function() { 
                  setShow(true, base);
                  }, 200);
              }
              else {
                this.setMenu('applications');
              }
              }
              }
              className={`navbar-item ${this.state.menu == 'applications' ? "navbar-live" : ""}`}>
              APPLICATIONS
            </a>

            <a onClick={ () => {
              if (this.state.withAnimation) {
                this.setShow(false, this);
                this.setMenu('features');
              
                const setShow = this.setShow;
                const base = this;
              
                setTimeout(function() { 
                  setShow(true, base);
                  }, 200);
              }
              else {
                this.setMenu('features');
              }
              }
              }
            
              className={`navbar-item ${this.state.menu == 'features' ? "navbar-live" : ""}`}>
              FEATURES
            </a>

            <a onClick={ () => {
              if (this.state.withAnimation) {
                this.setShow(false, this);
                this.setMenu('partners');
              
                const setShow = this.setShow;
                const base = this;
              
                setTimeout(function() { 
                  setShow(true, base);
                  }, 200);
              }
              else {
                this.setMenu('partners');
              }
              }
              }
              className={`navbar-item ${this.state.menu == 'partners' ? "navbar-live" : ""}`}>
              PARTNERS
            </a>
            
            
            
            { /*

            <div
              className="navbar-item has-dropdown is-hoverable">
              <a onClick={ () => this.setMenu('features') }
                className="navbar-link">
                FEATURES
              </a>

              <div className="navbar-dropdown">
                <a onClick={ () => this.setMenu('info_chat') } 
                  className="navbar-item">
                  CHAT
                </a>
                <a onClick={ () => this.setMenu('info_forms') } 
                  className="navbar-item">
                  DATA AND FORMS
                </a>
                <a onClick={ () => this.setMenu('info_fin_automation') } 
                  className="navbar-item">
                  FINANCIAL AUTOMATION
                </a>
                <a onClick={ () => this.setMenu('info_gis') } 
                  className="navbar-item">
                  GIS CAPABILITIES
                </a>
                <a onClick={ () => this.setMenu('info_gameification') } 
                  className="navbar-item">
                  GAMEIFICATION
                </a>
                <a onClick={ () => this.setMenu('info_reports') } 
                  className="navbar-item">
                  REPORTS
                </a>
                <hr className="navbar-divider" />
              </div>
            </div>
            */ }
            
            <a onClick={ () => {
              if (this.state.withAnimation) {
                this.setShow(false, this);
                this.setMenu('contact');
              
                const setShow = this.setShow;
                const base = this;
              
                setTimeout(function() { 
                  setShow(true, base);
                  }, 200);
              }
              else {
                this.setMenu('contact');
              }
              }
              }
              className={`navbar-item ${this.state.menu == 'contact' ? "navbar-live" : ""}`}>
              CONTACT
            </a>
          </div>
          :
          null
          }
          
          { this.state.loggedIn ?
            <div className="navbar-start noselect">
              <a onClick={ () => {
                if (this.state.withAnimation) {
                  this.setShow(false, this);
                  this.setMenu('organizations');
                
                  const setShow = this.setShow;
                  const base = this;
              
                  setTimeout(function() { 
                    setShow(true, base);
                    }, 200);
                }
                else {
                  this.setMenu('organizations');
                }
                }
                }
                className={`navbar-item ${this.state.menu == 'organizations' ? "navbar-live" : ""}`}>
                ORGANIZATIONS
              </a>
              
              <a onClick={ () => {
                if (this.state.withAnimation) {
                  this.setShow(false, this);
                  this.setMenu('projects');
                
                  const setShow = this.setShow;
                  const base = this;
              
                  setTimeout(function() { 
                    setShow(true, base);
                    }, 200);
                }
                else {
                  this.setMenu('projects');
                }
                }
                }
                className={`navbar-item ${this.state.menu == 'projects' ? "navbar-live" : ""}`}>
                PROJECTS
              </a>
              
              <a onClick={ () => {
                if (this.state.withAnimation) {
                  this.setShow(false, this);
                  this.setMenu('servers');
                
                  const setShow = this.setShow;
                  const base = this;
              
                  setTimeout(function() { 
                    setShow(true, base);
                    }, 200);
                }
                else {
                  this.setMenu('servers');
                }
                }
                }
                className={`navbar-item ${this.state.menu == 'servers' ? "navbar-live" : ""}`}>
                SERVERS
              </a>
            </div>
            :
            null
          }

          <div className="navbar-end">
            <div className="navbar-item-end">
              { !this.state.loggedIn ? 
                <div className="buttons">
                  <a id="signInLink" href="https://online.universe.org.za/" 
                    className="button sign-up">
                    <strong>SIGN UP</strong>
                  </a>
                  <a id="loginLink" href="https://online.universe.org.za/" className="button is-light">
                    <strong>LOG IN</strong>
                  </a>
                  <a id="adminLoginLink" className="button is-light" onClick={() => this.toggleLoginModal() }>
                    <strong>ADMIN LOG IN</strong>
                  </a>
                </div>
                :
                <div className="buttons">
                  <button id="logout" className="button is-light" onClick={() => this.logOff() } >
                    <strong>LOG OFF</strong>
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      </nav>
  	 
  	  { this.state.menu == 'introduction' ?
  	  <div className={`intro animated-slow-appear ${ this.state.show ? "show" : "" }`}>
  	    
  	  { /* <div className="title-content">
  	    <strong className="strong"><font style={{fontSize: "1.3em"}}>W</font>ELCOME TO <font style={{fontSize: "1.3em"}}>U</font>NIVERSE</strong>
            </div> */ }

      <div className="small_features_div">
  	    <img src="small_features.png"/>
  	  </div>

      <div className="phone-image">
      { this.screenshots.map( (_, i) =>
        <div key={ 'sc_' + i} className="screen-shot">
            <img className={ `animation-image ${ this.state.screenshotNo == i ? "ss-shown" : ""}`} 
            src={ this.screenshots[i] } width="318px" height="300px" />
        </div>
        )}

        { this.description[this.state.screenshotNo] != "" &&
          <div className="screen-shot-content">
            { this.description[this.state.screenshotNo] }
          </div>
        }
      </div>
  	  
  	  <div className="content-main">
  	    
  	    <div className="content-paragraph">
  	      
          <strong className="strong"><font style={{fontSize: "1.3em"}}>W</font>ELCOME TO <font style={{fontSize: "1.3em"}}>M</font>ESOVERSE</strong>
  	      
  	      <p>   &nbsp;</p>

  	      <p>Mesoverse is a Gig Economy operating system designed to enable jobs in the frantic, decentralized world of managing thousands of 
  	        customers, suppliers and middlemen from an App.  The system handles all the nuances of these interactions including payment, data 
  	        collection, HR and messaging.</p>
            <p>   &nbsp;</p>
  	      <p>With over 5 years of battle testing in real world situations we've solved all problems, so that you don't have to.</p>
  	    </div>

        
  	    
  	    { /*
  	    <div className="screenshots-panel">
  	      <table className="screenshots-table">
  	        <tbody>
  	          <tr>
  	            <td className="screenshots-td">
  	              <p className="screenshot-title">DATA CAPTURE</p>
  	              <img src="1.jpg" width="250px"/>
  	            </td>
  	            <td className="screenshots-td">
  	              <p className="screenshot-title">GIS MAPPING</p>
  	              <img src="2.jpg" width="250px"/>
  	            </td>
  	            <td className="screenshots-td">
  	              <p className="screenshot-title">PRODUCTIVITY</p>
  	              <img src="3.jpg" width="250px"/>
  	            </td>
  	          </tr>
  	        </tbody>
  	      </table>
        </div> 

  	    <div className="screenshots-expand-div">
  	      <i className="fa fa-arrow-right"></i>
  	    </div>
  	    <div className="screenshots-gap-div">
  	    </div>*/}
          </div> 
  	    
  	  <div id="background" className="background">
  	    { this.state.phoneX.map((_, i) =>
  	      <div key={ "phone" + i } className="floating-phone" style={{top: this.state.phoneY[i], left: this.state.phoneX[i]}}>
  	        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
	          width="25px" height="25px"
	          viewBox="0 0 27.442 27.442" style={{enableBackground: "new 0 0 27.442 27.442"}}>
              <g fill={ this.state.colors[i] }>
	            <path d="M19.494,0H7.948C6.843,0,5.951,0.896,5.951,1.999v23.446c0,1.102,0.892,1.997,1.997,1.997h11.546
		          c1.103,0,1.997-0.895,1.997-1.997V1.999C21.491,0.896,20.597,0,19.494,0z M10.872,1.214h5.7c0.144,0,0.261,0.215,0.261,0.481
		          s-0.117,0.482-0.261,0.482h-5.7c-0.145,0-0.26-0.216-0.26-0.482C10.612,1.429,10.727,1.214,10.872,1.214z M13.722,25.469
		          c-0.703,0-1.275-0.572-1.275-1.276s0.572-1.274,1.275-1.274c0.701,0,1.273,0.57,1.273,1.274S14.423,25.469,13.722,25.469z
		          M19.995,21.1H7.448V3.373h12.547V21.1z"/>
	          </g>
            </svg>
          </div>
        )}
          
        { this.state.moneyX.map((_, i) => 
          <div key={ "money" + i } className="floating-money" style={{top: this.state.moneyY[i], left: this.state.moneyX[i]}}>
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
              width="25px" height="25px"
	          viewBox="0 0 235.517 235.517" style={{enableBackground: "new 0 0 235.517 235.517"}}>
              <g fill={ this.state.moneyColors[i] }>
	            <path style={{ fill: this.state.moneyColors[i] }} d="M118.1,235.517c7.898,0,14.31-6.032,14.31-13.483c0-7.441,0-13.473,0-13.473
	            	c39.069-3.579,64.932-24.215,64.932-57.785v-0.549c0-34.119-22.012-49.8-65.758-59.977V58.334c6.298,1.539,12.82,3.72,19.194,6.549
	             	c10.258,4.547,22.724,1.697,28.952-8.485c6.233-10.176,2.866-24.47-8.681-29.654c-11.498-5.156-24.117-8.708-38.095-10.236V8.251
	             	c0-4.552-6.402-8.251-14.305-8.251c-7.903,0-14.31,3.514-14.31,7.832c0,4.335,0,7.843,0,7.843
	            	c-42.104,3.03-65.764,25.591-65.764,58.057v0.555c0,34.114,22.561,49.256,66.862,59.427v33.021
            		c-10.628-1.713-21.033-5.243-31.623-10.65c-11.281-5.755-25.101-3.72-31.938,6.385c-6.842,10.1-4.079,24.449,7.294,30.029
            		c16.709,8.208,35.593,13.57,54.614,15.518v13.755C103.79,229.36,110.197,235.517,118.1,235.517z M131.301,138.12
	             	c14.316,4.123,18.438,8.257,18.438,15.681v0.555c0,7.979-5.776,12.651-18.438,14.033V138.12z M86.999,70.153v-0.549
	            	c0-7.152,5.232-12.657,18.71-13.755v29.719C90.856,81.439,86.999,77.305,86.999,70.153z"/>
              </g>
            </svg>
	      </div>
	    )}
	    
	    { this.state.targetX.map((_, i) =>
	      <div key={ "target" + i } className="floating-target" style={{top: this.state.targetY[i], left: this.state.targetX[i]}}>
	        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	          width="25px" height="25px"
	          viewBox="0 0 330 330" style={{enableBackground: "new 0 0 330 330"}} >
              <g fill={ this.state.targetColors[i] } id="XMLID_813_">
                <path id="XMLID_814_" d="M15,130c8.284,0,15-6.716,15-15V30h85c8.284,0,15-6.716,15-15s-6.716-15-15-15H15C6.716,0,0,6.716,0,15
		          v100C0,123.284,6.716,130,15,130z"/>
	            <path id="XMLID_815_" d="M15,330h100c8.284,0,15-6.716,15-15s-6.716-15-15-15H30v-85c0-8.284-6.716-15-15-15s-15,6.716-15,15v100
	       	      C0,323.284,6.716,330,15,330z"/>
	            <path id="XMLID_816_" d="M315,200c-8.284,0-15,6.716-15,15v85h-85c-8.284,0-15,6.716-15,15s6.716,15,15,15h100
	       	      c8.284,0,15-6.716,15-15V215C330,206.716,323.284,200,315,200z"/>
	            <path id="XMLID_817_" d="M215,30h85v85c0,8.284,6.716,15,15,15s15-6.716,15-15V15c0-8.284-6.716-15-15-15H215
	         	    c-8.284,0-15,6.716-15,15S206.716,30,215,30z"/>
	            <path id="XMLID_818_" d="M75,165c0,8.284,6.716,15,15,15h60v60c0,8.284,6.716,15,15,15s15-6.716,15-15v-60h60
	         	    c8.284,0,15-6.716,15-15s-6.716-15-15-15h-60V90c0-8.284-6.716-15-15-15s-15,6.716-15,15v60H90C81.716,150,75,156.716,75,165z"/>
              </g>
            </svg>
          </div>
        )}
  	  </div>
  	  
  	  </div>
  	  : null
  	  }	

      { this.state.menu == 'applications' ?
  	  <div className={`animated-slow-appear ${ this.state.show ? "show" : "" }`}>
  	  
  	  
  	    <div className="content">
  	      <div className="content-paragraph">
  	        <div className="small_features_div">
  	          <img src="small_features.png"/>
  	        </div>
  	      
  	        <strong className="strong section-title"><font style={{fontSize: "1.3em"}}>A</font>PPLICATIONS</strong>
  	        <p>   &nbsp;</p>
  	        <p>   &nbsp;</p>
            <p style={{ marginBottom: "30px" }}>If your business can potentially operate with gig economy flavour, then Mesoverse can make that simple.  Mesoverse has 4 major components:
            </p>
            <ul style={{ listStyleType: "none" }}>
              <li style={{ paddingBottom: "10px" }}>
                <FontAwesomeIcon style={{ color: "#00DDFF"}} icon="list" />
                <strong style={{color: "#00DDFF", marginLeft: "20px"}}>Forms and responses</strong> published to and from the mobile apps
              </li>
              <li style={{ paddingBottom: "10px" }}>
                <FontAwesomeIcon style={{ color: "#00DDFF"}} icon="clock" />
                <strong style={{color: "#00DDFF", marginLeft: "20px"}}>Scheduling and coordination</strong> arranged with automation on the backoffice webapp and enforced on the mobile apps.
              </li>
              <li style={{ paddingBottom: "10px" }}>
                <FontAwesomeIcon style={{ color: "#00DDFF"}} icon="user-ninja" />
                <strong style={{color: "#00DDFF", marginLeft: "20px"}}>Policy and permissions</strong>
              </li>
              <li style={{ paddingBottom: "10px" }}>
                <FontAwesomeIcon style={{ color: "#00DDFF"}} icon="chart-bar" />
                <strong style={{color: "#00DDFF", marginLeft: "20px"}}>Dashboarding and reports</strong>
              </li>
            </ul>
            <p>
              These parts used together can manage the operations of most types of businesses that have many types of busy, on-the-ground people.  Operators use the backoffice webapp to handle the big
              picture.  Ground staff use the app to do their thing.
            </p>
            <img style={{margin: "40px", textAlign: "center", width: "50%"}} src="universecycle.png"/>
  	        <p>At present, Mesoverse is used in quite a few different applications:
              <ul  style={{ listStyleType: "none" }}>
                <li style={{ marginBottom: "30px"}}>
                  <FontAwesomeIcon style={{ color: "#00DDFF"}} icon="file" />
                  <strong style={{ color: "#00DDFF", marginLeft: "20px" }}>Document/project management</strong> - Paperwork, as part of business flow or project management,
                  can be an enormous burden.  This is particularly true when process needs to be properly organized for standards like ISO9001 or for 
                  compliance reasons.  Mesoverse is used in many situations to move the burden of paperwork to machine.  Users are prompted only when
                  necessary.
                </li>
                <li style={{ marginBottom: "30px"}}>
                  <FontAwesomeIcon style={{ color: "#00DDFF"}} icon="film" />
                  <strong style={{ color: "#00DDFF", marginLeft: "20px" }}>Film Production Management</strong> - With the passing of material amongst hands in film or TV
                  production, you need some management to ensure each role does their role fast and reliably.  Mesoverse is used to coordinate colorists, editors and sets to run TV 
                  operations efficiently.
                </li>
                <li style={{ marginBottom: "30px"}}>
                  <FontAwesomeIcon style={{ color: "#00DDFF"}} icon="truck" />
                  <strong style={{ color: "#00DDFF", marginLeft: "20px" }}>
                  Freight management</strong> - Need to organize the movement of goods, vehicles or deliveries.  
                  Mesoverse can manage the flow of orders, coordinate transport and notify warehousing of stock movements.</li>
                <li style={{ marginBottom: "30px"}}>
                  <FontAwesomeIcon style={{ color: "#00DDFF"}} icon="user" />
                  <strong style={{ color: "#00DDFF", marginLeft: "20px" }}>Human resources automation</strong> - Every business has to track and instruct 
                  its employment base.  Mesoverse offers features to allow clock-ins, timesheets, site data capture and organize meetings.  
                  These features can be coordinated to run the day-to-day.  Reports generated 
                  automatically will allow intervention when operations stray from normal.
                </li>
                <li style={{ marginBottom: "30px"}}>
                  <FontAwesomeIcon style={{ color: "#00DDFF"}} icon="folder" />
                  <strong style={{ color: "#00DDFF", marginLeft: "20px" }}>Survey or data collection</strong> - If interview-based surveying or even site based data collection
                  is necessary, Mesoverse has almost a decade of use in these conditions.  Even in mobile-reception-lean Africa, the system has held its ground.
                </li>
                <li style={{ marginBottom: "30px"}}>
                  <FontAwesomeIcon style={{ color: "#00DDFF"}} icon="exclamation-triangle" />
                  <strong style={{ color: "#00DDFF", marginLeft: "20px" }}>Trade permits</strong> - Many government agencies provide permits for various functions.  Municipalities provides
                  informal trade permits.  Regulators provide basic certification for food handling or alcohol licenses.  Mesoverse has been used to facilitate the capture and issuing
                  of permits in a few situations.  Low tech requirements (feature phone use) can be supported for these sorts of rollouts in Africa.
                </li>
                
              </ul>  
            </p>  
  	        
  	      </div>
        </div>
  	    <div className="partners-space"></div>
  	  </div>
  	  : null
  	  }
  	  
  	  { this.state.menu == 'partners' ?
  	  <div className={`partners-container animated-slow-appear ${ this.state.show ? "show" : "" }`}>
  	    
  	    <div className="content-partners">
  	      <div className="content-paragraph">
  	        <strong className="strong section-title"><font style={{fontSize: "1.3em"}}>P</font>ARTNERS</strong>
  	        <p>   &nbsp;</p>
  	        <p>   &nbsp;</p>
  	        <div style={{display: "none"}} className="partner-cell">
  	          <strong className="strong">University of Johannesburg</strong>
  	          <p>   &nbsp;</p>
  	          <p>A group called ResearchGO execute large-scale surveys on the platform.</p>
  	        </div>
  	        <div style={{display: "none"}} className="partner-cell">
  	          <strong className="strong">PROMT</strong>
  	          <p>   &nbsp;</p>
  	          <p>PROMT manage a cohort of merchandizers on the platform.</p>
  	        </div>
  	        <div className="partner-cell">
  	          <div className="partner-cell-logo">
  	            <img className="partner-cell-image" src="/gpglogo.jpg"/>
  	          </div>
  	          <strong className="strong">Gauteng Provincial Government</strong>
  	          <p>   &nbsp;</p>
  	          <p>The South African government uses Mesoverse as a permit system to coordinate informal traders.  The system allows their operators to manage risks, especially during the coronavirus pandemic.
  	          Mapping of the businesses enables the control of density and allows traders to book area in a first-come-first-serve model.
  	          </p>
  	          <p>If you represent a government interested in this nature of application please contact us for more information.</p>
  	        </div>
  	        
  	        <p>
  	          More arriving soon...
  	        </p>
  	        <br></br>
  	        <span className="fa-stack fa-2x">
              <i className="fas fa-circle fa-stack-2x" style={{color: "#00DDFF"}}></i>
  	          <i className="fa fa-wrench fa-stack-1x fa-pulse" style={{color: "#000000"}}></i>
  	        </span>
  	      </div>
  	    </div>
  	    
  	    <div className="partners-space"></div>
  	  </div>
  	  : null
  	  }
  	  
  	  { this.state.menu == 'features' ?
  	  <div className={`animated-slow-appear ${ this.state.show ? "show" : "" }`}>
  	  
  	  
  	    <div className="content">
  	      <div className="content-paragraph">
  	        <div className="small_features_div">
  	          <img src="small_features.png"/>
  	        </div>
  	      
  	        <strong className="strong section-title"><font style={{fontSize: "1.3em"}}>F</font>EATURES</strong>
  	        <p>   &nbsp;</p>
  	        <p>   &nbsp;</p>
  	        <p> Mesoverse is a gig economy "operating system" that works with people across a cloud.  
  	        By "operating system" we mean a platform that keeps the complexity of communication, payment and scheduling infrastructure away from application designers.
  	        The system runs across mobile devices, cloud infrastructure and simplifies the organizing of a business.</p>  
  	        
  	        <p> All you have to do is, essentially, draw your business in a series of flow schematics to have it agree with your organization.  There is, also, a little
  	        homework setting up forms and payment criteria.</p>
  	        
  	        The system, as a whole, can be divided into four main components:
  	        <ol className="major-features-ol">
  	          <li className="major-features-li" onClick={ () => this.setState({features_io_ul_vis: !this.state.features_io_ul_vis})}>
  	          
  	            <font className="feature-line">Forms and Responses</font> - Through the use of mobile app or web forms, data is requested and captured.  These forms can be structured or designed in a multitude of ways.  
  	            In addition to typical string or multiple choice inputs.  The system supports media from camera, QR code scanners, audio consent recordings and a growing list of other exotic types.  There is also a messenger.
  	          
  	            <ul className={`features-ul ${ this.state.features_io_ul_vis ? "show" : "" }`}>
  	              <li><font className="feature-line">Data and form processing</font> - Mesoverse was originally designed to collect interview based survey information.  Surveys were large scale and conducted in poor mobile network environments.  We had to develop a form system on mobile app that was robust and configurable.
  	                Forms such as leave request forms, or complex forms like asset register lists are easy to populate:
  	                <div className="desktop-screenshot">
  	                  <img src="screenshot1.png" width="100%"/>
  	                </div>
  	                The system supports many different data entry types:
  	                <ul>
  	                  <li><b><i className="fa fa-question fa-gap"></i>Text and numeric entries</b> with many types of validation including regular expressions</li>
  	                  <li><b><i className="fa fa-list fa-gap"></i>Multiple choice questions</b> that can branch in complex ways</li>
  	                  <li><b><i className="fa fa-camera fa-gap"></i>Camera photos or image upload</b> with options on image resizing to reduce upload data use</li>
  	                  <li><b><i className="fa fa-qrcode fa-gap"></i>QR code lists</b> that are captured by camera</li>
  	                </ul>
  	                Various validation schemes can be configured to check validity of email addresses and mobile phone numbers (even by sms).
  	                <br></br>
  	                <br></br>
  	                Forms can be enormous, with up to 100 fields per page and as many pages as necessary.  If this is necessary.  They are continuously saved on disk while captured so that no loss of data is possible.  
  	              </li>
  	              <li><font className="feature-line">Messenger</font> - Mesoverse has a tightly integrated chat messenger with multimedia capabilities.  It can be used to communicate with groups, between individuals with privacy or
  	                with record keeping for official conversations. 
  	              </li>
  	                
  	            </ul>
  	            
  	            { !this.state.features_io_ul_vis ? 
  	            <div className="features-expand-div">
  	              <i className="fa fa-arrow-down"></i>&nbsp;&nbsp;Click to expand
  	            </div>
  	            : null
  	            }
  	            
  	            { this.state.features_io_ul_vis ? 
  	            <div className="features-expand-div">
  	              <i className="fa fa-arrow-up"></i>
  	            </div>
  	            : null
  	            }
  	          </li>
  	          <li className="major-features-li" onClick={ () => this.setState({features_sched_ul_vis: !this.state.features_sched_ul_vis})}>
  	          
  	            <font className="feature-line">Scheduling and Coordination</font> - Forms, when captured, may trigger events or operations.  Alternatively, operations may be triggered on more abstract things like time
  	            or the reception of an email.  This may trigger the release of other forms, or the API execution of a payment or prompting the order from a supplier.  The scheduler allows the design of this business
  	            logic.
  	            
  	            <ul className={`features-ul ${ this.state.features_sched_ul_vis ? "show" : "" }`}>
  	              <li><font className="feature-line">Form targetting</font> - Form assignments can be sent to participants via a calendar requiring them to perform certain tasks within a timeframe.  Or sent as part of a assignment.
  	              Targets can be geographically linked to a coordinate or limited to within a timeframe.
  	              
  	              <div className="desktop-screenshot">
  	                <img src="screenshot3.png" width="100%"/>
  	              </div>
  	                
  	              </li>
  	              <li><font className="feature-line">Automation of payments and buying of mobile data</font> - System components enable the purchasing of mobile data and executing payments in supported territories.  
  	              This enables the automatic payment of salaries, purchases of inventory or just keeping the people on the ground connected to the system with data.  Payments can be scheduled and managed on a dashboard.</li>
  	              <li><font className="feature-line">Stock ordering</font> - An event may trigger the ordering of stock.  Tools are provided to interface with suppliers via email, API or providing them with a limited function login.</li>
  	            </ul>
  	            
  	            { !this.state.features_sched_ul_vis ? 
  	            <div className="features-expand-div">
  	              <i className="fa fa-arrow-down"></i>&nbsp;&nbsp;Click to expand
  	            </div>
  	            : null
  	            }
  	            
  	            { this.state.features_sched_ul_vis ? 
  	            <div className="features-expand-div">
  	              <i className="fa fa-arrow-up"></i>
  	            </div>
  	            : null
  	            }
  	          </li>
  	          <li className="major-features-li" onClick={ () => this.setState({features_pol_ul_vis: !this.state.features_pol_ul_vis})}>
  	          
  	            <font className="feature-line">Policy and Permissions</font> - Users can have roles.  These roles can be permitted to do certain things and not other in Access Control Lists (ACL).  Mesoverse
  	            supports these
  	            
  	            <ul className={`features-ul ${ this.state.features_pol_ul_vis ? "show" : "" }`}>
  	            </ul>
  	            
  	            { /*!this.state.features_pol_ul_vis ? 
  	            <div className="features-expand-div">
  	              <i className="fa fa-arrow-down"></i>&nbsp;&nbsp;Click to expand
  	            </div>
  	            : null
  	            }
  	            
  	            { this.state.features_pol_ul_vis ? 
  	            <div className="features-expand-div">
  	              <i className="fa fa-arrow-up"></i>
  	            </div>
  	            : null */
  	            }
  	          </li>
  	          <li className="major-features-li" onClick={ () => this.setState({features_rep_ul_vis: !this.state.features_rep_ul_vis})}>
  	          
  	            <font className="feature-line">Reporting and Dashboards</font> - Interrogating the data can be executed in a few different ways.
  	            
  	            <ul className={`features-ul ${ this.state.features_rep_ul_vis ? "show" : "" }`}>
  	              <li><font className="feature-line">GIS</font> - Mesoverse is completely GIS enabled.
  	                <div className="desktop-screenshot">
  	                  <img src="screenshot2.png" width="100%"/>
  	                </div>
  	                A user can schedule data to be collected at certain points or constrain collection within a region.  Plots, charts or heatmaps can be constructed over the geography to allow for efficient visualization.
  	              </li>
  	              <li><font className="feature-line">Reporting</font> - A completely customizable reporting tool is available.  The tool allows emailing of scheduled reports and live incident reports when intervention is required.</li>
                </ul>
                
                { !this.state.features_rep_ul_vis ? 
  	            <div className="features-expand-div">
  	              <i className="fa fa-arrow-down"></i>&nbsp;&nbsp;Click to expand
  	            </div>
  	            : null
  	            }
  	            
  	            { this.state.features_rep_ul_vis ? 
  	            <div className="features-expand-div">
  	              <i className="fa fa-arrow-up"></i>
  	            </div>
  	            : null
  	            }
  	          </li>
  	        </ol>    
  	      </div>
  	    </div>
  	  </div>
  	  : null
  	  }
  	  
  	  { this.state.menu == 'contact' ?
  	  <div className={`animated-slow-appear ${ this.state.show ? "show" : "" }`}>
  	    <div className="partners-map">
  	      <img className="stacked-map-1 map-image" src="worldmap.svg" width="100%"/>
  	      <svg className="stacked-map-2" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
            width="100%"
	        viewBox="0 0 950 620">
            <g>
	          
	          <rect x="520" y="400" width="7em" height="2em" fill="#000000AA" rx="15" />
	          <text x="530" y="430" fill="#00DDFF" style={{font: "bold 0.5em sans-serif"}}>Relentless, Johannesburg</text>
	          <circle cx="530" cy="415" r="3" stroke="black" fill="red" strokeWidth="1" fill="red" />
	          <rect x="200" y="165" width="5.5em" height="2em" fill="#000000AA" rx="15" />
	          <text x="210" y="195" fill="#00DDFF" style={{font: "bold 0.5em sans-serif"}}>Relentless, Chicago</text>
	          <circle cx="210" cy="180" r="3" stroke="black" fill="red" strokeWidth="1" fill="red" />
            </g>
          </svg>
  	    </div>
  	    
  	    <div className="contact-content">
  	      <div className="contact-paragraph">
  	        <strong className="strong section-title"><font style={{fontSize: "1.3em"}}>C</font>ONTACT</strong>
  	        <p>   &nbsp;</p>
  	        <ul className="contact-ul">
  	          <li><font className="place-line">Johannesburg</font> - Edinburgh Building, Hyde Park Lane, Hyde Park, Johannesburg, South Africa - Contact Number: +27 (72) 771 9330</li>
  	          <li><font className="place-line">Chicago</font> - 
  	            Arriving soon...
  	            <span className="fa-stack fa-2x">
                  <i className="fas fa-circle fa-stack-2x" style={{color: "#00DDFF"}}></i>
  	              <i className="fa fa-wrench fa-stack-1x fa-pulse" style={{color: "#000000"}}></i>
  	            </span>
  	          </li>
  	          <li><font className="place-line">Email:</font> sales@relentless.co.za</li>
  	        </ul>
  	      </div>
  	    </div>
  	    
  	    <div className="contact-space"></div>
  	  </div>
  	  : null
  	  }
  	  
  	  { this.state.menu == 'organizations' ?
  	    <Organizations token={ this.state.token }/>
  	  : null
  	  }
  	  
  	  { this.state.menu == 'projects' ?
  	    <Projects />
  	  : null
  	  }
  	  
  	  { this.state.menu == 'servers' ?
  	    <Servers token={ this.state.token }/>
  	  : null
  	  }
  	  
  	<div className="legal">
  	  <div className="legal-image">
  	    <img width="100px" src="relentless-logo.png">
  	    </img>
  	  </div>
  	  <p>&copy; Copyright 2014 - 2021 Relentless Technologies (Pty) Ltd All Rights Reserved.</p>
  	</div>
    
    { this.state.loggingIn ? 
      <div className="modal-background">
        <div className="modal-center">
          <label>Email:</label>
          <input type="text" value={ this.state.email } onChange={ (event) => this.setEmail(event.target.value) }/>
          <label>Password:</label>
          <input type="password" value={ this.state.password } onChange={ (event) => this.setPassword(event.target.value) }/>
          
          <button className="loginButtons" onClick={ () => this.attemptLogin() }>
            LOGIN
          </button>
          
          <button className="loginButtons" onClick={ () => this.toggleLoginModal() }>
            CLOSE
          </button>
        </div>
      </div>
      :
        null
    }
    
    </section>
    );
  };
}

export default App;
